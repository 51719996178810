<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 billing_module_table">
			<div class="header_boxes">
				<div class="d-flex align-items-center justify-content-between">
					<div class="billing-date-calendar">
						<date-range-picker ref="picker" opens="center" :locale-data="{
							direction: 'ltr',
							format: 'yyyy-mm-dd',
							separator: ' - ',
							applyLabel: 'Apply',
							cancelLabel: 'Cancel',
							weekLabel: 'W',
							customRangeLabel: 'Custom Range',
							daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
							monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
							firstDay: 0
						}" singleDatePicker="range" v-model="dateRange" :dateRange="dateRange" :ranges="ranges" :key="dateRangeKey">
							<template v-slot:input="picker">
								<slot v-if="picker.startDate != null && picker.endDate != null">
									{{ formatDateRange(picker.startDate) }} - {{ formatDateRange(picker.endDate) }}
								</slot>
								<slot v-else>
									All Time Data
								</slot>
							</template>
							<template v-slot:footer="{ rangeText, clickCancel, clickApply }">
								<div class="drp-buttons d-flex justify-content-between align-items-end">
									<div class="selected-date-billig">
										<span class="start-date-blng" v-if="extractDates(rangeText, 'start') != ''">
											<label class="float-start fw-600" style="padding: calc(var(--scale-ratio) * 6px);">START</label>
											<input type="text" :value="extractDates(rangeText, 'start')" @change="handleDate('startDatePicker')" @blur="handleDate('startDatePicker')" ref="startDatePicker" @keyup="this.keyupdate('startDatePicker')" id="startDatePicker">
										</span>
										<span class="end-date-blng" v-if="extractDates(rangeText, 'end') != ''">
											<label class="float-start fw-600" style="padding: calc(var(--scale-ratio) * 6px);">END</label>
											<input type="text" :value="extractDates(rangeText, 'end')" @change="handleDate('endDatePicker')" @blur="handleDate('endDatePicker')" ref="endDatePicker" @keyup="this.keyupdate('endDatePicker')" id="endDatePicker">
										</span>
									</div>
									<div>
										<button class="cancelBtn btn btn-sm btn-secondary" type="button"
											@click="clickCancel">Cancel</button>
										<button class="applyBtn btn btn-sm btn-success" type="button"
											@click="clickApply">Apply</button>
									</div>
								</div>
							</template>
						</date-range-picker>
					</div>
					<div>
						<button class="comman_brdr_btn big_btn mx20" @click="exportCSV" v-if="this.selectedItems.length > 0">Export CSV</button>
						<button class="comman_btn big_btn" @click="manageSubmit" v-if="this.selectedItems.length > 0">Update & Resubmit</button>
					</div>
				</div>
				<div class="d-flex align-items-center my-3">
					<div class="position-relative">
						<div class="search manage-demographic-srch">
							<img class="serch_icon" src="/images/search-icon-gray.svg" alt="">
							<input type="text" @keyup="patientSearch" class="form-control" placeholder="Search Demographic"
								v-model="patient_search" ref="globalSearch" :class="{ active: isSelectPatient }">
						</div>
						<div class="search_drop_box src_new_box billing-srch-demogpc" v-if="patientList.length > 0">
							<slot v-for="(item, index) in patientList" :key="index">
								<div class="con_drop_line"
									@click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
									<div class="row px-2 align-items-center">
										<div class="col-md-12">
											<div class="man_haeding">
												{{ item.last_name }}, {{ item.first_name }}
												<span>({{ item.gender }})</span>
											</div>
											<ul>
												<li>
													DOB: {{ item.dob }}
												</li>
												<li>
													HIN: {{ item.health_insurance_no }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</slot>
						</div>
					</div>
					<div class="manage-provider-select">
						<div class="selact_dropdown">
							<!-- <selectCheckBox fieldName="invoice_list_provider" :isOpenDivClassChange="isOpenDivClassChange" :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" :key="select_box_key" :isShowDoctorIcon="true" :isOpenDivClassChange="true" :isUnselectAll="isUnselectAll" divMainClass="" doctorImgIcon="/images/doctor-man-icon-gray.svg" /> -->
							<selectCheckBox fieldName="invoice_list_provider" :dataSource="provider_list"
								:labelName="tagProviderLabel" @clicked="selected_provider" :key="filter_select_box_key"
								:isShowDoctorIcon="true" :isOpenDivClassChange="isOpenDivClassChange"
								:isUnselectAll="isUnselectAll" divMainClass=""
								:isActiveInvoiceListProviderFilter="isActiveInvoiceListProviderFilter"
								:doctorImgIcon="invoiceListDoctorImageIcon" />
						</div>
					</div>
					<div class="manage_status_option">
						<CustomDropDown :options="status_option" :initialValue="this.status_text"
							@item-selected="handleItemSelected" fieldName="status"
							:invoiceListCookieStorageStatus="invoiceListCookieStorageStatus"></CustomDropDown>
					</div>
					<CustomDropDown :options="type_option" :initialValue="this.type_text"
						@item-selected="handleItemSelected" fieldName="type"
						:invoiceListCookieStorageType="invoiceListCookieStorageType"></CustomDropDown>
				</div>
			</div>
			<div class="table-responsive billing-list mang-tbl-list manage-invoice-table manage_invoice_table">
				<table class="table mb-0">
					<thead>
						<tr>
							<th class="hover_unset w-50px" style="width:calc(var(--scale-ratio) * 50px);"><input class="form-check-input" type="checkbox" v-model="this.selectAllChk" @change="checkSelectAll"></th>
							<th style="width:calc(var(--scale-ratio) * 198px);">SERVICE DATE </th>
							<th style="width:calc(var(--scale-ratio) * 210px);">DEMOGRAPHIC</th>
							<th style="width:calc(var(--scale-ratio) * 240px);">PROVIDER </th>
							<th style="width:calc(var(--scale-ratio) * 210px);">SERVICE </th>
							<th style="width:calc(var(--scale-ratio) * 133px);">BILLED </th>
							<th style="width:calc(var(--scale-ratio) * 133px);">PAID </th>
							<th style="width:calc(var(--scale-ratio) * 180px);">STATUS </th>
							<th class="text-end" style="width:calc(var(--scale-ratio) * 136px);">TYPE </th>
							<th class="hover_unset text-end" style="width: calc(var(--scale-ratio) * 121.5px);"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in this.allInvoiceList" :key="index"
							:class="{ 'active': (selectedItems.includes(item.id)) }">
							<td>
								<input class="form-check-input" type="checkbox" :checked="selectedItems.includes(item.id)" @change="checkSelect(item.id)">
							</td>
							<td>{{ item.service_date }}</td>
							<td>{{ $filters.strToUpperCase(item.patient_name) }}</td>
							<td>{{ $filters.strToUpperCase(item.provider_name) }}</td>
							<td><span class="max-srvc-code">{{ $filters.strToUpperCase(item.service_codes) }} {{ item.service_code_description }}</span></td>
							<td>{{ '$' + item.total_fee_submitted }}</td>
							<td> <slot v-if="item.total_fee_received!=0.00">{{ '$' + item.total_fee_received }}</slot></td>
							<td>{{ $filters.strToUpperCase(item.status) }}</td>
							<td class="text-end">
								{{
									item.type.toLowerCase() === 'ontario'
									? (item.claim_type === 'HCP' ? 'OHIP' : item.claim_type)
									: $filters.strToUpperCase(item.type)
								}}
							</td>
							<td class="text-end">
								<img src="/images/dots-icon.svg" @click="manageInvoice(item.invoice_id, item.type)"
									class="dot_icon">
							</td>
						</tr>
					</tbody>
					<tfoot class="manag-list-totale-foot">
						<tr>
							<td style="width:calc(var(--scale-ratio) * 50px);"></td>
							<td colspan="3" style="width:calc(var(--scale-ratio) * 598px);">
								<p class="mb-0 manage-invc-total">
									<label class="manage-invc-title cursor-text">TOTAL NUMBER OF INVOICES</label>
									<span class="divider-virtcl-line">|</span> {{this.invoicesSummary.total_invoices}}
								</p>
							</td>
							<td colspan="2" style="width:calc(var(--scale-ratio) * 343px);">
								<p class="mb-0 manage-invc-total">
									<label class="manage-invc-title cursor-text">TOTAL BILLED</label>
									<span class="divider-virtcl-line">|</span> ${{ formatCurrency(this.invoicesSummary.total_fee_submitted) }}
								</p>
							</td>
							<td colspan="4" style="width:calc(var(--scale-ratio) * 570px);">
								<p class="mb-0 manage-invc-total">
									<label class="manage-invc-title cursor-text">TOTAL PAID</label>
									<span class="divider-virtcl-line">|</span> ${{ formatCurrency(this.invoicesSummary.total_fee_received) }}
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
	<div class="modal fade" id="manage-invoice-model" tabindex="-1" style="display: none;" aria-hidden="true"
		ref="invoicePop" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl invoice_status_popup">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeInvoiceView"></button>
					<div class="d-flex mb-2">
						<div class="manage_status mr-150">STATUS | <span>{{
							$filters.strToUpperCase(this.invoiceDetailsData.status) }}</span></div>
						<div class="health-num-inl" v-if="this.invoiceDetailsData.status == 'Rejected'">EH2 | <span>Health
								Card Number is ineligible</span></div>
					</div>
					<div class="box">
						<div class="row">
							<div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-8 createinvic_tabs">
								<ul class="export_tabs">
									<li :class="{ active: isServiceFax }" @click="changeTabsOpt('service_code')">
										<span>Service Codes</span>
									</li>
									<li :class="{ active: isAdvanceSettings }" @click="changeTabsOpt('advance_settings')"
										v-if="this.invoice_type_text == 'ONTARIO'"><span>Advanced Settings</span></li>
								</ul>
								<div class="d-flex justify-content-between flex-column tab_contain">
									<div class="fax_menu overflow-auto" v-if="isServiceFax">
										<div class="srvc-table mang-status-invoice">
											<table class="table text-center mb-0">
												<thead>
													<tr>
														<th style="width:calc(var(--scale-ratio) * 160px);">Billing Code</th>
														<th style="width:calc(var(--scale-ratio) * 130px);">Quantity</th>
														<th style="width:calc(var(--scale-ratio) * 90px);" v-if="this.invoice_type_text == 'ONTARIO'">%</th>
														<th style="width:calc(var(--scale-ratio) * 144px);">Value</th>
														<th style="width:calc(var(--scale-ratio) * 160px);" v-if="this.invoice_type_text != 'ONTARIO'">Description</th>
														<th style="width:calc(var(--scale-ratio) * 160px);" v-if="this.invoice_type_text == 'ONTARIO'">Dx Code</th>
														<th style="width:calc(var(--scale-ratio) * 150px);border-right: 0;">Date</th>
														<th style="border: 0;background: transparent;width: calc(var(--scale-ratio) * 223px);"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in this.serviceCodeDetails" :key="index">
														<td>{{ $filters.strToUpperCase(item.service_code) }}</td>
														<td>{{ item.number_of_service }}</td>
														<td v-if="this.invoice_type_text == 'ONTARIO'">{{ item.discount }}
														</td>
														<td>{{ '$' + item.fee_submitted }}</td>
														<td v-if="this.invoice_type_text == 'ONTARIO'">{{ item.dx_code }}</td>
														<td v-else>{{ item.service_code_description }}</td>
														<td>{{ item.service_date }}</td>
														<td></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

									<div class="fax_menu overflow-auto" v-if="isAdvanceSettings">
										<div class="ad-from-sect">
											<div class="row">
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>HEALTH NUMBER</label>
														<input v-model="this.advanceSettingData.healthNumber" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>VERSION CODE</label>
														<input v-model="this.advanceSettingData.versionCode" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<!-- <label>PROVINCE CODE</label>
                                                        <input v-model="this.advanceSettingData.provinceCode" type="text" class="form-control" readonly> -->
														<label>PROVINCE CODE</label>
														<CustomDropDown :options="stateData"
															:initialValue="this.invoice_details.province_code_text"
															:isDisabled="true" v-model="this.invoice_details.province_code"
															@item-selected="handleItemSelected" fieldName="province_code">
														</CustomDropDown>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>CLAIM PAYEE</label>
														<input v-model="this.advanceSettingData.claimPayee" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>LAST NAME</label>
														<input v-model="this.advanceSettingData.lastName" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>FIRST NAME</label>
														<input v-model="this.advanceSettingData.firstName" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>DATE OF BIRTH</label>
														<input v-model="this.advanceSettingData.dateOfBirth" type="text"
															class="form-control" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-3">
													<div class="adv-stg-from">
														<label>GENDER</label>
														<input v-model="this.advanceSettingData.gender" type="text"
															class="form-control" readonly>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from overhd-drop-tect drp-width-big">
														<label>CLAIM TYPE</label>
														<CustomDropDown :options="claim_type_option"
															:initialValue="claim_type_option_selected"
															v-model="this.advanceSettingData.claimType"
															@item-selected="handleItemSelected" :isDisabled="true"
															:isFromViewInvoice="true" fieldName="claim_type">
														</CustomDropDown>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>REFERRAL ID</label>
														<input type="text" class="form-control" readonly
															v-model="this.invoice_details.referral_hcp_number">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>REGISTRATION NUMBER (FOR RMB)</label>
														<input data-v-f6d43346="" type="text" class="form-control"
															v-model="this.invoice_details.rmb_registration_number" readonly>
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>VISIT LOCATION</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.visit_location_short">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>SERVICE LOCATION INDICATOR</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.service_location_short">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>ADMISSION DATE</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.inpatient_admission_date">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>REFERRING LAB LICENSE NUMBER</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.referring_lab_license_number">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>GROUP NUMBER OVERRIDE</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.group_number">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>SPECIALTY OVERRIDE</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.specialty_code_desc">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from">
														<label>MASTER NUMBER</label>
														<input data-v-f6d43346="" type="text" class="form-control" readonly
															v-model="this.invoice_details.master_number">
													</div>
												</div>
												<div class="col-12 col-sm-6 col-md-4 col-lg-4">
													<div class="adv-stg-from manual-rvw-switch">
														<label>MANUAL REVIEW</label>
														<label class="switch d-block cursor-default">
															<input type="checkbox" id="darkModeToggle" disabled
																:checked="this.invoice_details.manual_review == '1'">
															<span class="sw-slider"></span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div class="ttl-sect">
											<div class="d-flex justify-content-end">
												<p class="t-ttl-h">TOTAL |</p>
												<p class="ttl-price">${{ this.serviceCodeTotalFees }}</p>
											</div>
										</div>
										<div class="btn_part">
											<button class="comman_brdr_btn big_btn text-red" @click="invoiceDelete()"
												v-if="retrieveInvoiceType == 'Private'">Delete</button>
											<div class="d-inline-block float-end">
												<button class="comman_btn big_btn mx20" v-if="showEditButton"
													@click="editInvoice(this.retrieveInvoiceId, this.retrieveInvoiceType)">Edit</button>
												<button class="comman_btn big_btn mx20"
													v-if="(invoiceDetailsData.status == 'Pending' && retrieveInvoiceType == 'Private')"
													@click="invoicePayReceived(this.retrieveInvoiceId)">Payment Received</button>
												<button class="comman_brdr_btn big_btn mx20"
													v-if="this.invoiceDetailsData.status == 'Completed'"
													@click="printInvoice">Print <img src="images/loader.gif" v-if="printBtnLoader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
												<button class="comman_brdr_btn big_btn mx20 me-0"
													@click="closeInvoiceView">Close</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 invoice-left-bar manage_invoice_sidebar">
								<div>
									<div class="pt-dashbrd">
										<p class="lbl-iput"><span>INVOICE TYPE</span></p>
										<div class="heading_selact_drodwn inv-type-drop-li manage-invc-type-box">
											<CustomDropDown :options="invoice_type_opt"
												:initialValue="this.invoice_type_text"
												fieldName="invoice_type"></CustomDropDown>
										</div>
									</div>

									<p class="lbl-iput"><span>DEMOGRAPHIC</span></p>
									<div class="position-relative">
										<div class="search">
											<img class="serch_icon" src="/images/search-icon.svg" alt="">
											<input type="text" @keyup="patientSearch" class="form-control w-100 cursor-default"
												placeholder="Search Demographic" v-model="invoiceViewPatientName"
												readonly>
										</div>
										<div class="search_drop_box src_new_box" v-if="patientList.length > 0">
											<slot v-for="(item, index) in patientList" :key="index">
												<div class="con_drop_line"
													@click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
													<div class="row px-2 align-items-center">
														<div class="col-md-12">
															<div class="man_haeding">
																{{ item.last_name }}, {{ item.first_name }}
																<span>({{ item.gender }})</span>
															</div>
															<ul>
																<li>
																	DOB: {{ item.dob }}
																</li>
																<li>
																	HIN: {{ item.health_insurance_no }}
																</li>
															</ul>
														</div>
													</div>
												</div>
											</slot>
										</div>
									</div>
								</div>

								<div class="mt-20 pt-dashbrd">
									<div class="row p-0">
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
											<p class="ptnt-dtls demog-health-line"><span>Health # |</span>{{ this.patientHcvDetails }}</p>
										</div>
										<div class="col-8 col-sm-8 col-md-8 col-lg-8 p-0">
											<p class="ptnt-dtls"><span>Date of Birth |</span>{{ this.patientDateOfBirth }}
											</p>
										</div>
										<div class="col-4 col-sm-4 col-md-4 col-lg-4 p-0">
											<p class="ptnt-dtls ms-0"><span class="w-auto">Age |</span>{{ this.patientAge }}
											</p>
										</div>
									</div>
								</div>
								<div>
									<p class="lbl-iput"><span>PROVIDER</span></p>
									<div class="selact_dropdown bigs-slct-dr h-auto d-inline-block w-100 manage-provider-box">
										<selectCheckBox fieldName="create_invoice_provider" :isDisabled="true"
											:dataSource='provider_list' :labelName="tagViewProviderLabel"
											:displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="true"
											:isUnselectAll="isUnselectAll" divMainClass=""
											:isMultiSelection="isMultiSelection" />
									</div>
								</div>
								<div class="mt-10 pt-dashbrd">
									<div class="row p-0">
										<div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
											<p class="ptnt-dtls"><span>Provider ID |</span>
												<slot v-if="this.invoice_details.roleId == 2">{{ trimToSixDigits(this.invoice_details.cpsid) }}</slot>
												<slot v-if="this.invoice_details.roleId == 3">{{ trimToSixDigits(this.invoice_details.cnoid) }}</slot>
											</p>
										</div>
										<div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
											<p class="ptnt-dtls"><span>Specialty
													|</span>
													<slot v-if="this.invoice_details.specialty_code">{{ this.invoice_details.specialty_code }}</slot>
													<slot v-else>{{ this.invoice_details.provider_specialty_code }}</slot>
											</p>
										</div>
										<div class="col-12 col-sm-6 col-md-6 col-lg-6 p-06">
											<p class="ptnt-dtls"><span class="w-auto">Visit Location
													|</span>{{ this.invoice_details.visit_location_short }}</p>
										</div>
										<div class="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
											<p class="ptnt-dtls"><span>Group #
													|</span>{{ this.invoice_details.group_billing_no }}</p>
										</div>
									</div>
								</div>
								<div class="mt-20">
									<p class="smallhead">PREVIOUS INVOICES</p>
									<div class="table-responsive previ-innce previous_table">
										<table class="table text-center mb-0">
											<thead>
												<tr>
													<th style="width: calc(var(--scale-ratio) * 105px);">Date</th>
													<th>Codes</th>
													<th>Dx</th>
													<th style="width: calc(var(--scale-ratio) * 100px);">Type</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in this.previousInvoiceData" :key="index">
													<td class="white-space-pre">{{ item.service_date }}</td>
													<td>{{ $filters.strToUpperCase(item.service_code) }}</td>
													<td>{{ item.dx_code }}{{ item.service_code_description }}</td>
													<td>{{ $filters.strToUpperCase(item.type) }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="resubmit-model" tabindex="-1" style="display: none;" aria-hidden="true" ref="true">
		<div class="modal-dialog modal-dialog-centered modal-xl resubmit-model-small">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="cancelResubmit"></button>
					<div class="box">
						<div>
							<p class="selected-msg-rj mb-50">Not all selected invoices are rejected / pending. Would you still like to 
								resubmit all of the selected invoices?
							</p>
						</div>
					</div>
					<div class="btn_part text-center">
						<button class="comman_brdr_btn mx17" @click="cancelResubmit">Cancel</button>
						<button class="comman_btn mx17" @click="confirmResubmit">Resubmit</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import selectCheckBox from "../base/selectCheckBox.vue";
import $ from "jquery";
import DateRangePicker from 'vue3-daterange-picker';
import moment from "moment";

export default {
	data() {
		return {
			dateRangeKey: 0,
			dateRange: {
				startDate: this.getMonthStartDate(),
				endDate: this.getMonthEndDate()
			},
			claim_type_option_selected: "HCP - Health Claims Program | <span class='fw-300 fs-14'>Ontario</span>",
			type_option: [
				{ value: "M", title: "OHIP" },
				{ value: "F", title: "Ontario - WCB" },
				{ value: "F", title: "Ontario - RMB" },
				{ value: "F", title: "Private" },
				{ value: "F", title: "All" },
			],
			status_option: [
				{ value: "M", title: "Pending" },
				{ value: "F", title: "Submitted" },
				{ value: "F", title: "Rejected" },
				{ value: "F", title: "Completed" },
				{ value: "F", title: "Deleted" },
				{ value: "F", title: "Saved" },
				{ value: "F", title: "All" },
			],
			claim_type_option: [
				{ value: "HCP", title: "HCP - Health Claims Program | <span class='fw-300 fs-14'>Ontario</span>" },
				{ value: "WCB", title: "WCB - Workplace Compensation Board" },
				{ value: "RMB", title: "RMB - Reciprocal Medical Billing | <span class='fw-300 fs-14'>Other Provinces besides Quebec<span>" },
			],
			stateData: [
				{ value: "AB", title: "AB - Alberta" },
				{ value: "BC", title: "BC - British Columbia" },
				{ value: "MB", title: "MB - Manitoba" },
				{ value: "NB", title: "NB - New Brunswick" },
				{ value: "NL", title: "NL - Newfoundland" },
				{ value: "NS", title: "NS - Nova Scotia" },
				{ value: "ON", title: "ON - Ontario" },
				{ value: "PE", title: "PE - Prince Edward Island" },
				{ value: "QC", title: "QC - Quebec" },
				{ value: "SK", title: "SK - Saskatchewan" },
				{ value: "NT", title: "NT - Northwest Territories" },
				{ value: "NU", title: "NU - Nunavut" },
				{ value: "YT", title: "YT - Yukon" },
				{ value: "OT", title: "OT - Other" },
			],
			status_text: 'Select Status',
			type_text: 'Select Type',
			patientList: [],
			patient_search: "",
			provider_list: [],
			tagProviderLabel: "Select Provider(s)",
			tagViewProviderLabel: "Select Provider(s)",
			form: {
				provider_ids: [],
				status: '',
				type: '',
				patient_id: null,
				start_date: '',
				end_date: '',
			},
			allInvoiceList: [],
			isServiceFax: true,
			isAdvanceSettings: false,
			serviceCodeDetails: [],
			serviceCodeTotalFees: null,
			invoiceDetailsData: [],
			invoiceViewPatientName: '',
			patientAge: null,
			patientDateOfBirth: '',
			patientHcvDetails: '',
			patientProviderId: null,
			patientProviderName: '',
			previousInvoiceData: [],
			advanceSettingData: {
				healthNumber: '',
				versionCode: '',
				provinceCode: '',
				claimPayee: '',
				lastName: '',
				firstName: '',
				dateOfBirth: '',
				gender: '',
				claimType: '',
				billingStatus: '',
				referralHcpNumber: '',
			},
			retrieveInvoiceId: null,
			retrieveInvoiceType: '',
			selectAllChk: false,
			allItems: [],
			selectedItems: [],
			isUnselectAll: false,
			isSelectPatient: false,
			displayProviderDiv: "active",
			invoice_details: [],
			invoiceListCookieStorageStatus: false,
			invoiceListCookieStorageType: false,
			isOpenDivClassChange: false,
			isActiveInvoiceListProviderFilter: false,
			invoiceListDoctorImageIcon: "/images/doctor-man-icon-gray.svg",
			resubmitResultArray: [],
			filter_select_box_key: null,
			invoicesSummary: {
				total_invoices: 0,
				total_fee_submitted: 0,
				total_fee_received: 0
			},
			printBtnLoader: false
		}
	},
	components: {
		sidebar,
		CustomDropDown,
		selectCheckBox,
		DateRangePicker
	},
	methods: {
		invoicePayReceived(invoiceId){
			axios.post("invoice/private/update", { id: invoiceId, status: 'Completed', action: 'payment_received' })
				.then((response) => {
					this.$filters.moshaToast(response.data.message, "success");
					this.getInvoiceList();
					this.closeInvoiceView();
				})
		},
		getMonthStartDate() {
			const now = new Date();
			const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
			return startDate;
		},
		getMonthEndDate() {
			const now = new Date();
			const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return endDate;
		},
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).after(errorElement);
			}
			const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if (obj == 'startDatePicker') {
					this.dateRange.startDate = formattedDate;
				} else {
					this.dateRange.endDate = formattedDate;
				}
				const startDate = new Date(this.dateRange.startDate);
				const endDate = new Date(this.dateRange.endDate);

				if (endDate < startDate) {
					this.$filters.moshaToast("End Date should be greater than Start Date", "danger");
					if(obj=='startDatePicker'){
						this.dateRange.startDate = null;
					}else{
						this.dateRange.endDate = null;
					}
					$(`#${obj}`).val("");
				}
			} else {
				if(formattedDate!=''){
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		editInvoice(invoiceId, invoiceType)
		{
			window.$("#manage-invoice-model").modal("hide");
			this.$router.push({
				name: "InvoiceMasterCreate",
				query: {
				invoiceId: invoiceId,
				invoiceType: invoiceType
				},
			});
		},
		extractDates(dateRange, type) {
			const [startDate, endDate] = dateRange.split(" - ");
			return (type == "start") ? startDate : endDate;
		},
		formatDateRange(date) {
			if (!date) return '';
			return moment(date).local().format('YYYY/MM/DD');
		},
		confirmResubmit() {
			this.$store.state.loader = true;
			window.$("#resubmit-model").modal("hide");
			axios.post('invoice/resubmit', { 'invoice_ids': this.resubmitResultArray })
				.then((response) => {
					if (response.status == 200) {
						this.allItems = [];
						this.selectAllChk = false;
						this.getInvoiceList();
						this.selectedItems = [];
						this.resubmitResultArray = [];
						this.$store.state.loader = false;

						this.$filters.moshaToast(response.data.message, "success");
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(error.response.data.message, "error");
						this.selectedItems = [];
						this.resubmitResultArray = [];
					} else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		cancelResubmit() {
			window.$("#resubmit-model").modal("hide");
		},
		loadInvoiceFilterFromCookie() {
			const invoiceFilterCookie = this.getCookie('invoice_filter') || '{}';
			let invoiceFilter;

			try {
				invoiceFilter = JSON.parse(invoiceFilterCookie);
			} catch (e) {
				console.error('Failed to parse invoice filter cookie:', e);
				invoiceFilter = {};
			}

			if (invoiceFilterCookie) {
				const invoiceFilter = JSON.parse(invoiceFilterCookie);
				this.dateRange.startDate = (invoiceFilter.start_date != undefined) ? invoiceFilter.start_date : this.dateRange.startDate;
				this.dateRange.endDate = (invoiceFilter.end_date != undefined) ? invoiceFilter.end_date : this.dateRange.endDate;
				this.dateRangeKey = this.dateRangeKey+1;

				this.form.start_date = this.formatDate(this.dateRange.startDate);
				this.form.end_date = this.formatDate(this.dateRange.endDate);
			}
			
			if (invoiceFilter.patient_id && invoiceFilter.patient_name) {
				this.form.patient_id = invoiceFilter.patient_id;
				this.patient_search = invoiceFilter.patient_name;
				this.isSelectPatient = true;
			}

			if (invoiceFilter.provider_ids && invoiceFilter.provider_label) {
				this.form.provider_ids = invoiceFilter.provider_ids;
				this.tagProviderLabel = invoiceFilter.provider_label;
				if (this.tagProviderLabel != "Select Provider(s)") {
					this.isActiveInvoiceListProviderFilter = true;
					this.filter_select_box_key = 0;
					this.invoiceListDoctorImageIcon = "/images/doctor-man-icon.svg";
				} else {
					this.isActiveInvoiceListProviderFilter = false;
					this.filter_select_box_key++;
					this.invoiceListDoctorImageIcon = "/images/doctor-man-icon-gray.svg";
				}
			}

			if (invoiceFilter.status) {
				this.form.status = invoiceFilter.status;
				this.status_text = invoiceFilter.status;
				this.invoiceListCookieStorageStatus = true;
			}

			if (invoiceFilter.type) {
				this.form.type = invoiceFilter.type;
				this.type_text = invoiceFilter.type;
				this.invoiceListCookieStorageType = true;
			}

			this.getInvoiceList();
		},
		getCookie(name) {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; ${name}=`);
			if (parts.length === 2) return parts.pop().split(';').shift();
			return null;
		},
		setCookie(name, value, days) {
			const expiryDate = new Date();
			expiryDate.setDate(expiryDate.getDate() + days);
			document.cookie = `${name}=${value}; expires=${expiryDate.toUTCString()}; path=/`;
		},
		deleteCookie(name) {
			document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		},
		checkSelect(id) {
            // Handle individual item selection/deselection
            const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
            if (index !== -1) {
                this.selectedItems.splice(index, 1); // Remove id from selectedItems
            } else {
                this.selectedItems.push(id)
            }

            // Update selectAllChk based on selectedItems length
            this.selectAllChk = this.selectedItems.length === this.allItems.length;
		},
		checkSelectAll() {
			this.selectAllChk = !this.selectAllChk
			if (this.selectAllChk) {
				this.selectedItems = [...this.allItems];
			} else {
				this.selectedItems = [];
			}
		},
		closeInvoiceView() {
			document.getElementById("manage-invoice-model").style.display = "none";
			this.serviceCodeDetails = [];
			this.serviceCodeTotalFees = null;
			this.invoiceDetailsData = [];
			this.invoiceViewPatientName = '';
			this.patientAge = null;
			this.patientHcvDetails = '';
			this.patientProviderId = null;
			this.patientProviderName = '';
			this.previousInvoiceData = [];
			this.advanceSettingData.healthNumber = '';
			this.advanceSettingData.versionCode = '';
			this.advanceSettingData.provinceCode = '';
			this.advanceSettingData.claimPayee = '';
			this.advanceSettingData.lastName = '';
			this.advanceSettingData.firstName = '';
			this.advanceSettingData.dateOfBirth = '';
			this.advanceSettingData.gender = '';
			this.advanceSettingData.claimType = '';
			this.advanceSettingData.billingStatus = '';
			this.advanceSettingData.referralHcpNumber = '';
			this.retrieveInvoiceType = '';
			this.retrieveInvoiceId = null;
			this.isAdvanceSettings = false;
			this.isServiceFax = true;
			window.$("#manage-invoice-model").modal("hide");
		},
		changeDateRangePicker() {
			let startDateVal = this.formatDate(this.dateRange.startDate);
			let endDateVal = this.formatDate(this.dateRange.endDate);
			if (startDateVal && endDateVal) {
				if (startDateVal == "1970-01-01" && endDateVal == "1970-01-01") {
					this.form.start_date = null;
					this.form.end_date = null;
					this.dateRange.startDate = null;
					this.dateRange.endDate = null;

					/* Invoice Filters value set on cookie */
					let invoiceFilter = JSON.parse(this.getCookie('invoice_filter')) || {};
					invoiceFilter.start_date = null;
					invoiceFilter.end_date = null;
					this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
				}
				else 
				{
					this.form.start_date = startDateVal;
					this.form.end_date = endDateVal;

					/* Invoice Filters value set on cookie */
					let invoiceFilter = JSON.parse(this.getCookie('invoice_filter')) || {};
					invoiceFilter.start_date = this.dateRange.startDate;
					invoiceFilter.end_date = this.dateRange.endDate;
					this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
				}
			}
			this.getInvoiceList();
		},
		formatDate(date) {			
			return moment(date).format('YYYY-MM-DD');
		},
		changeTabsOpt(type) {
			this.isServiceFax = false
			this.isAdvanceSettings = false
			if (type == 'service_code') {
				this.isServiceFax = true
			} else if (type == 'advance_settings') {
				this.isAdvanceSettings = true
			}
		},
		patientSearch() {
			this.isSelectPatient = false;
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if (this.patient_search.length == 0) {
				this.patientList = [];
				this.form.patient_id = null;

				let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
				if (invoiceFilter) {
					delete invoiceFilter.patient_name;
					delete invoiceFilter.patient_id;
					if (Object.keys(invoiceFilter).length === 0) {
						this.deleteCookie('invoice_filter');
					} else {
						this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
					}
				}
				this.getInvoiceList();
			}
			if (this.patient_search.length > 0) {
				this.isSelectPatient = true;
			}
		},
		handleItemSelected(values) {
			if (values.fieldName === 'status' && (values.item.value && values.item.title)) {
				this.form.status = values.item.title;
				/* Invoice Filters value set on cookie */
				let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
				invoiceFilter.status = values.item.title;
				this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
				this.getInvoiceList();
			} else if (values.fieldName === 'type' && (values.item.value && values.item.title)) {
				this.form.type = values.item.title;
				/* Invoice Filters value set on cookie */
				let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
				invoiceFilter.type = values.item.title;
				this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
				this.getInvoiceList();
			} else if (values.fieldName === "province_code") {
				this.form.province_code_text = values.item.title;
				this.form[values.fieldName] = values.item.value;
			}
		},
		selectPatient(id, patient_name) {
			// this.$store.state.patient_document.form.patient_id = id
			if (id && patient_name) {
				this.patient_search = patient_name;
				this.form.patient_id = id;
				this.isSelectPatient = true;
				this.getInvoiceList();
				this.patientList = [];
				// this.patient_search = '';

				/* Invoice Filters value set on cookie */
				let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
				invoiceFilter.patient_id = id;
				invoiceFilter.patient_name = patient_name;
				this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
			}
		},
		selected_provider(result) {
			this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
			if (this.form.provider_ids.length == 1) {
				this.tagProviderLabel = `${this.form.provider_ids.length} Provider Selected`;
			} else if (this.form.provider_ids.length > 1) {
				this.tagProviderLabel = `${this.form.provider_ids.length} Providers Selected`;
			} else {
				this.tagProviderLabel = "Select Provider(s)";
			}

			/* Invoice Filters value set on cookie */
			let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
			invoiceFilter.provider_ids = this.form.provider_ids;
			invoiceFilter.provider_label = this.tagProviderLabel;
			this.setCookie('invoice_filter', JSON.stringify(invoiceFilter), 1);
			if (this.tagProviderLabel != "Select Provider(s)") {
				this.isActiveInvoiceListProviderFilter = true;
				this.filter_select_box_key = 0;
				this.invoiceListDoctorImageIcon = "/images/doctor-man-icon.svg";
			}
			else {
				this.isActiveInvoiceListProviderFilter = false;
				this.filter_select_box_key++;
				this.invoiceListDoctorImageIcon = "/images/doctor-man-icon-gray.svg";
			}

			this.getInvoiceList();
		},
		getProviderList() {
			axios.post("json_list/provider", { roleId: [2,3] })
				.then((response) => {
					this.provider_list = response.data.data.map((item) => {
						return { id: item.id, name: item.full_name, checked: false };
					});

					/* Invoice Filters value set on cookie */
					let invoiceFilter = JSON.parse(this.getCookie('invoice_filter') || '{}');
					if (invoiceFilter.provider_ids && invoiceFilter.provider_label) {
						this.provider_list = this.provider_list.map((item) => {
							return {
								...item,
								checked: invoiceFilter.provider_ids.includes(item.id)
							};
						});
						this.form.provider_ids = invoiceFilter.provider_ids;
						this.tagProviderLabel = invoiceFilter.provider_label;

						if (this.tagProviderLabel != "Select Provider(s)") {
							this.isActiveInvoiceListProviderFilter = true;
							this.filter_select_box_key = 0;
							this.invoiceListDoctorImageIcon = "/images/doctor-man-icon.svg";
						} else {
							this.isActiveInvoiceListProviderFilter = false;
							this.filter_select_box_key++;
							this.invoiceListDoctorImageIcon = "/images/doctor-man-icon-gray.svg";
						}
						this.getInvoiceList();
					}
				});
		},
		invoiceDelete() {
			this.$swal({
				title: 'Are you sure to DELETE this invoice?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons

				// customClass: {
				//     confirmButton: 'comman_btn permission_remove_btn'
				// },
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post('invoice/delete', { 'invoice_id': this.retrieveInvoiceId, 'type': this.retrieveInvoiceType })
						.then((response) => {
							if (response.status == 200) {
								this.closeInvoiceView();
								this.getInvoiceList();
								this.$store.state.loader = false;
								this.$filters.moshaToast(response.data.message, "success");
								window.$("#manage-invoice-model").modal("hide");
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							} else if(error.response.status === 402){
								window.location.href = error.response.data.redirect_url;
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						});
				}
				else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
				}
			})
		},
		manageInvoice(invoiceId, type) {
			this.invoice_type_text = this.$filters.strToUpperCase(type);
			axios.post('invoice/retrieve', { 'invoice_id': invoiceId, 'type': type })
				.then((response) => {
					if (response.status == 200) {
						this.retrieveInvoiceId = invoiceId;
						this.retrieveInvoiceType = type;
						this.serviceCodeDetails = response.data.data.invoice_details;
						this.invoice_details = response.data.data.invoice_details_data;
						if (this.invoice_details.visit_location) {
							const [code, description] = this.invoice_details.visit_location.split(/[-–]/).map(str => str.trim());
							console.log(description)
							this.invoice_details.visit_location_short = code;
						} else {
							this.invoice_details.visit_location_short = '';
						}

						
						if (this.invoice_details.service_location) {
							const [code, description] = this.invoice_details.service_location.split(/[-–]/).map(str => str.trim());
							console.log(description)
							this.invoice_details.service_location_short = code;
						} else {
							this.invoice_details.service_location_short = '';
						}

						this.serviceCodeTotalFees = response.data.data.total;
						this.invoiceDetailsData = response.data.data.invoice_details_data;
						this.invoiceViewPatientName = this.$filters.strToUpperCase(this.invoiceDetailsData.patient_name);
						this.patientDateOfBirth = this.invoiceDetailsData.dob;
						this.patientAge = this.$filters.patientCalculatedAge(this.invoiceDetailsData.dob, true);
						let province_code = '';
						if(this.invoiceDetailsData.province_code){
							province_code = this.invoiceDetailsData.province_code;
						}else{
							if(this.invoiceDetailsData.health_card_province_id){
								const matchedRecord = this.stateData.find(item => item.value.toLowerCase() === this.invoiceDetailsData.health_card_province_id.toLowerCase());
								if (matchedRecord) {
									province_code = matchedRecord.value;
								}
							}
						}
						this.patientHcvDetails = this.$filters.strToUpperCase(this.invoiceDetailsData.health_insurance_no + ' ' + this.invoiceDetailsData.health_card_ver) + ' (' + province_code + ') ';
						this.patientProviderId = this.invoiceDetailsData.provider_id;
						this.patientProviderName = this.invoiceDetailsData.provider_name;
						this.previousInvoiceData = response.data.data.previous_invoice_data;
						this.tagViewProviderLabel = this.patientProviderName;

						this.advanceSettingData.healthNumber = this.$filters.strToUpperCase(this.invoiceDetailsData.health_insurance_no);
						this.advanceSettingData.versionCode = this.$filters.strToUpperCase(this.invoiceDetailsData.health_card_ver);
						this.advanceSettingData.dateOfBirth = this.invoiceDetailsData.dob;
						this.advanceSettingData.billingStatus = this.invoiceDetailsData.status;
						if (this.invoiceDetailsData.claim_type) {
							let claimOption = this.claim_type_option.filter(option => option.value === this.invoiceDetailsData.claim_type);
							if (claimOption.length > 0) {
								this.advanceSettingData.claimType = claimOption[0].title;
								this.claim_type_option_selected = claimOption[0].title;
							}
						}

						this.advanceSettingData.referralHcpNumber = (this.invoiceDetailsData.referral_hcp_number != "") ? this.invoiceDetailsData.referral_hcp_number : '';
						if (this.invoiceDetailsData.state_name && this.invoiceDetailsData.state_sort_name) {
							this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_sort_name + ' - ' + this.invoiceDetailsData.state_name;
						} else if (this.invoiceDetailsData.state_name) {
							this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_name;
						}
						this.advanceSettingData.provinceCode = this.invoiceDetailsData.province_code;
						if (this.invoiceDetailsData.claim_payee) {
							if (this.invoiceDetailsData.claim_payee == "P") {
								this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Provider";
							} else if (this.invoiceDetailsData.claim_payee == "S") {
								this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Patient";
							}
						}
						if (this.invoiceDetailsData.gender) {
							switch (this.invoiceDetailsData.gender) {
								case 'M':
									this.advanceSettingData.gender = 'Male';
									break;
								case 'F':
									this.advanceSettingData.gender = 'Female';
									break;
								case 'O':
									this.advanceSettingData.gender = 'Other';
									break;
								case 'T':
									this.advanceSettingData.gender = 'Trans';
									break;
								default:
									break;
							}
						}
						this.advanceSettingData.firstName = this.$filters.strToUpperCase(this.invoiceDetailsData.first_name);
						this.advanceSettingData.lastName = this.$filters.strToUpperCase(this.invoiceDetailsData.last_name);
						this.$store.state.loader = false;
						window.$("#manage-invoice-model").modal("show");
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		manageSubmit() {
			let resultArray = [];
			let anotherStatusFound = false;

			for (let itemId of this.selectedItems) {
				let invoice = this.allInvoiceList.find(invoice => invoice.id === itemId);
				if (invoice) {
					resultArray.push({
						type: invoice.type,
						status: invoice.status,
						invoice_id: invoice.invoice_id
					});
					if (invoice.type.trim().toLowerCase() === 'private') {
						this.$filters.moshaToast("Private Invoice is not applicable for this action", "error");
						return false;
					}
					if (invoice.status.trim() !== 'Rejected' && invoice.status.trim() !== 'Pending') {
						anotherStatusFound = true;
					}
				}
			}
			if (anotherStatusFound) {
				this.resubmitResultArray = resultArray;
				window.$("#resubmit-model").modal("show");
			}
			else {
				this.$store.state.loader = true;
				axios.post('invoice/resubmit', { 'invoice_ids': resultArray })
					.then((response) => {
						if (response.status == 200) {
							this.allItems = [];
							this.selectAllChk = false;
							this.getInvoiceList();
							this.selectedItems = [];
							this.resubmitResultArray = [];
							this.$store.state.loader = false;
							this.$filters.moshaToast(response.data.message, "success");
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.$store.state.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data), "error");
						} else if(error.response.status === 402){
							window.location.href = error.response.data.redirect_url;
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			}



		},
		getInvoiceList() {
			this.$store.state.loader = true;
			if (this.dateRange.startDate && this.dateRange.endDate) {
				this.form.start_date = moment(this.dateRange.startDate).format('YYYY/MM/DD');
				this.form.end_date = moment(this.dateRange.endDate).format('YYYY/MM/DD');
			}else{
				this.form.start_date = null;
				this.form.end_date = null;
			}
			axios.post('invoice/list', this.form)
				.then((response) => {
					if (response.status == 200) {
						this.allInvoiceList = response.data.data;
						this.$store.state.loader = false;

						this.allInvoiceList.forEach(item => {
							this.allItems.push(item.id)
						});

						this.invoicesSummary = this.allInvoiceList.reduce((acc, invoice) => {
							acc.total_invoices += 1;
							acc.total_fee_submitted += parseFloat(invoice.total_fee_submitted.replace(',',''));
							acc.total_fee_received += parseFloat(invoice.total_fee_received);
							return acc;
						}, {
							total_invoices: 0,
							total_fee_submitted: 0,
							total_fee_received: 0
						});
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target) && !event.target.closest(`.search_drop_box`)) {
				this.patientList = [];
			}
			const popupeElement = this.$refs.invoicePop;
			if (popupeElement && !popupeElement.contains(event.target)) {
				this.closeInvoiceView();
			}
		},
		printInvoice() {
			this.printBtnLoader = true
			axios.post('invoice/private/print', { 'id': this.retrieveInvoiceId })
				.then((response) => {
					this.printBtnLoader = false
					if (response.status == 200) {
						if (response.data.data.pdf_path) {
							window.open(response.data.data.pdf_path, '_blank');
						}
					} else {
						console.log(response.data.msg)
					}
				}).catch(error => {
					this.printBtnLoader = false
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		formatCurrency(value) {
			return value.toFixed(2);
		},
		exportCSV(){
			let resultArray = [];
			for (let itemId of this.selectedItems) {
				let invoice = this.allInvoiceList.find(invoice => invoice.id === itemId);
				if (invoice) {
					resultArray.push({
						type: invoice.type,
						invoice_id: invoice.invoice_id
					});
				}
			}

			this.$store.state.loader = true;
			axios.post('invoice/export-invoices', { 'invoice_ids': resultArray }, {
				responseType: 'blob'
			})
			.then((response) => {
				this.$store.state.loader = false;
				const blob = new Blob([response.data], { type: 'text/csv' });

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'invoices.csv';

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				this.selectedItems = [];
			}).catch(error => {
				console.log(error)
				this.$store.state.loader = false;
			});
		},
		trimToSixDigits(number) {
			if (number) {
				let numberString = number.toString();
				let trimmedString = numberString.slice(0, 6);
				let trimmedNumber = parseInt(trimmedString, 10);
				return trimmedNumber;
			} else {
				return ""
			}
		},
	},
	mounted() {
		this.getProviderList(),
		this.loadInvoiceFilterFromCookie();
		document.addEventListener('click', this.handleClickOutside);
		let $this = this;
		$(document).ready(() => {
			$(document).off("click", ".applyBtn");
			$(document).on("click", ".applyBtn", () => {
				$this.changeDateRangePicker();
			});
		});
		const { invoiceId, invoiceType } = this.$route.query;
		if(invoiceId && invoiceType){
			setTimeout(() => {
				this.manageInvoice(invoiceId,invoiceType);
			}, 500);
		}
	},
	computed: {
		showEditButton() {
			const { status, claim_type } = this.invoiceDetailsData;
			return !(status === 'Submitted' && claim_type === 'HCP') && status !== 'Pending';
		},
		ranges() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);

			// Define the start and end of the current month
			let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
			let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

			// Define the start and end of the last month
			let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
			let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

			// Define the start of the week (Sunday) and end of the week (Saturday)
			let thisWeekStart = new Date(today);
			thisWeekStart.setDate(today.getDate() - today.getDay());
			let thisWeekEnd = new Date(thisWeekStart);
			thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

			// Define the start and end of the last week
			let lastWeekStart = new Date(thisWeekStart);
			lastWeekStart.setDate(thisWeekStart.getDate() - 7);
			let lastWeekEnd = new Date(lastWeekStart);
			lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

			// Define the date range for the last 7 days
			let last7DaysStart = new Date(today);
			last7DaysStart.setDate(today.getDate() - 6);

			// Define the date range for the last 30 days
			let last30DaysStart = new Date(today);
			last30DaysStart.setDate(today.getDate() - 29);

			// Define the date range for the last 90 days
			let last90DaysStart = new Date(today);
			last90DaysStart.setDate(today.getDate() - 89);

			// Define the date range for the last 180 days
			let last180DaysStart = new Date(today);
			last180DaysStart.setDate(today.getDate() - 179);

			let range = {
				'Today': [today, today],
				'Yesterday': [yesterday, yesterday],
				'This Week': [thisWeekStart, thisWeekEnd],
				'Last Week': [lastWeekStart, lastWeekEnd],
				'Last 7 Days': [last7DaysStart, today],
				'Last 30 Days': [last30DaysStart, today],
				'Last 90 Days': [last90DaysStart, today],
				'Last 180 Days': [last180DaysStart, today],
				'This Month': [thisMonthStart, thisMonthEnd],
				'Last Month': [lastMonthStart, lastMonthEnd],
				'This Year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
				'All Time': [null, null],
			};

			return range;
		}
	},

}
</script>

<style scoped>
	.mang-tbl-list .form-check-input:disabled {background-color: transparent;opacity: 0.4;}
</style>