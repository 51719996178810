<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 billing_module_table analysis_report">
            <div class="header_boxes analysis-rprt-provider">
                <div class="selact_dropdown d-inline-block">
                        <selectCheckBox 
                            :dataSource='provider_list' 
                            :labelName="tagProviderLabel" 
                            @clicked="selected_provider" 
                            :isShowDoctorIcon="true" 
                            :doctorImgIcon="invoiceListDoctorImageIcon"
                            :isOpenDivClassChange="true"
                            :isUnselectAll="isUnselectAll"
                            :isDisabled="isDisabledProviderSelection"
                            :key="filter_select_box_key"
                        />
                </div>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-75px"></th>
                            <th scope="col">YEAR </th>
                            <th scope="col">MONTH</th>
                            <th scope="col">PROVIDER </th>
                            <th scope="col">REPORT NAME </th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody v-if="this.reportData.length > 0">
                        <tr v-for="(item,index) in this.reportData" :key="index">
                            <td></td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.month }}</td>
                            <td>{{ formatUserName(item.user_name) }}</td>
                            <td>{{ item.report_name }}</td>
                            <td>
                                <span class="popup_link" @click="download(item.file_path)">Download</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue'
import selectCheckBox from "../base/selectCheckBox.vue";
import axios from "@/scripts/axios.js";

export default {
    data() {
        return {
            provider_list:[],
            tagProviderLabel:"Select Provider(s)",
            form:{
                provider_ids:[],
                physician_text: "Select Users",
            },
            reportData: [],
            isUnselectAll: false,
            authUserId: null,
            authUserRole: '',
            isDisabledProviderSelection: false,
			invoiceListDoctorImageIcon: "/images/doctor-man-icon-gray.svg",
            filter_select_box_key: null,
        }
    },
    components: {
        sidebar,
        selectCheckBox
    },
    methods: {
        checkPermission(){
            if (this.authUserId && this.authUserRole && this.authUserRole != "Staff") {
                return true
            } else {
                return false
            }
        },
        formatUserName(name) {
            if (!name) return '';
            const parts = name.split(' ');
            if (parts.length < 2) return name.toUpperCase();
            const lastName = parts.pop().toUpperCase();
            const firstName = parts.join(' ').toUpperCase();
            return `${firstName}, ${lastName}`;
        },
        getProviderList() {
            axios.post("json_list/provider", { roleId: [2,3] })
                .then((response) => {
                    const providerData = response.data.data;
                    console.log(providerData)
                    this.filter_select_box_key = 0;
                    if (this.authUserId && (this.authUserRole === "Nurse" || this.authUserRole === "Doctor")) {
                        this.filter_select_box_key++;
                        this.provider_list = providerData
                            .filter(item => {
                                if (item.user_id == this.authUserId) {
                                    return true;
                                }
                                return false;
                            })
                            .map(item => {
                                return { id: item.id, user_id: item.user_id, name: item.full_name, checked: true };
                            });

                        this.selected_provider(this.provider_list);
                    } else {
                        this.provider_list = providerData.map((item) => {
                            return { id: item.id, user_id: item.user_id, name: item.full_name, checked: false };
                        });
                    }
                }).catch(error => {
                    this.loader = false
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
                        window.location.href = error.response.data.redirect_url;
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        },
        selected_provider(result){
            this.form.provider_ids = result.filter(item => item.checked).map(item => item.user_id);
            if (this.form.provider_ids.length == 1) {
                this.tagProviderLabel = `${this.form.provider_ids.length } Provider Selected`;
            } else if (this.form.provider_ids.length > 1){
                this.tagProviderLabel = `${this.form.provider_ids.length } Providers Selected`;
            } else {
                this.tagProviderLabel = "Select Provider(s)";
            }
            if (this.authUserId && (this.authUserRole === "Nurse" || this.authUserRole === "Doctor")) {
                if (this.form.provider_ids.length === 0) {
                    this.reportData = [];
                }
                else
                {
                    this.getReportData();
                }
            }
            else
            {
                this.getReportData();
            }
        },
        getReportData(){
            this.$store.state.loader = true;
            axios.post('admin/billing-analysis-reports', {'filter_user_id': this.form.provider_ids})
                .then((response) => {
                    if (response.status == 200) {
                        this.reportData = response.data.data
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                    this.$store.state.loader = false;
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
                        window.location.href = error.response.data.redirect_url;
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        },
        async download(fileName){
            this.$store.state.loader = true;
            axios.get(`admin/billing-report-download/${fileName}`)
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
                            const url = response.data.url;
                            if (!url) {
                                // alert('Failed to get download URL.');
                                this.$filters.moshaToast('Failed to get download URL.', "error");
                                return false;
                            }

                            // Create a temporary link to download the file
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName); // Optional: set filename for download
                            link.setAttribute('target', '_blank'); // Open in a new tab
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        },
    },
    mounted() {
        this.authUserId = localStorage.getItem("userId");
        this.authUserRole = localStorage.getItem("authUserRole");
        
        if(this.authUserId && this.authUserRole === "Staff")
        {
            this.isDisabledProviderSelection = true;
            if (!this.checkPermission()) {
                this.$swal({
                    title: 'Error!',
                    text: 'You Do Not Have Permission to Access',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'comman_btn permission_remove_btn'
                    },
                });
                return false
            }
        } else if(this.authUserId && (this.authUserRole === "Nurse" || this.authUserRole === "Doctor")) {
            this.form.provider_ids.push(this.authUserId);
            this.getProviderList();
        }
        else
        {
            this.getProviderList();
            this.getReportData();
        }
    },
    computed: {

    }
}
</script>
